// contact service
export default {
    install: (Vue) => {
      //private
      const route = process.env.VUE_APP_API_BASE_URL + '/api/';
  
      //public
      Vue.$choiceService = {
        find(choiceEntityName, params) {
          return Vue.axios.get(`${route}${choiceEntityName}`, {
            params: params
          });
        }
      };
    }
  };
//   //example of use
//   var grants = await Vue.$grantService.find({
//     name: 'test'
//   });