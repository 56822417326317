// Single Type service
export default {
    install: (Vue) => {
      //private
      const route = process.env.VUE_APP_API_BASE_URL + '/api/';
  
      //public
      Vue.$singleTypeService = {
        find(key, params) {
            return Vue.axios.get(`${route}${key}`, {
              params: params
            });
          },

        findOne(key, params) {
          return Vue.axios.get(`${route}${key}`, {
            params: params
          });
        }
      };
    }
  };
//   //example of use
//   var grants = await Vue.$grantService.find({
//     name: 'test'
//   });