// content resource service
export default {
    install: (Vue) => {
      //private
      const route = process.env.VUE_APP_API_BASE_URL + '/api/content-resources?populate=*';

  
      //public
      Vue.$contentResourceService = {
        async find(params) {
          var contentResources = await Vue.axios.get(route, {
            params: params
          });

          var ret = { 
            data: { 
              data: contentResources.data.data.map(contentResource => ({
                ...contentResource,
                attributes: {
                  ...contentResource.attributes,
                  contentAreaOfFocus: contentResource.attributes.areaOfFocus?.data?.attributes?.name,
                  contentResourceType: contentResource.attributes.category?.data?.attributes?.name,
                  contentResourceFormat: contentResource.attributes.format?.data?.attributes?.name,
                }
              }))
            }
          };

          return ret;
        },
  
        findOne(key, params) {
          return Vue.axios.get(`${route}${key}`, {
            params: params
          });
        },

        findVimeoDownloadLink(videoId) {
          return Vue.axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/content-resources/download/${videoId}`);
        }
      };
    }
  };