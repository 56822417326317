<template>
  <v-card elevation="12" style="background: white !important; width:100%">
    <div class="login-card mb-5" style="margin: 0 10%">
      <v-card-text class="no-left-pad-md">
        <h2>Register</h2>
      </v-card-text>
      <v-form style="padding-top: 5%;" v-on:submit.prevent="register">
        <v-row>
          <v-col class="py-1" cols="12" md="6">
            <v-text-field label="First Name*" name="first" type="text" autocomplete="off" outlined dense
              :disabled="sending" v-validate.disable="'required'" :error-messages="errors.collect('First Name')"
              data-vv-name="First Name" required v-model="registration.firstName"></v-text-field>
          </v-col>

          <v-col class="py-1" cols="12" md="6">
            <v-text-field label="Last Name*" name="last" type="text" outlined autocomplete="off" dense
              :disabled="sending" v-validate.disable="'required'" :error-messages="errors.collect('Last Name')"
              data-vv-name="Last Name" required v-model="registration.lastName"></v-text-field>
          </v-col>
          <v-col class="py-1" cols="12">
            <v-text-field label="Email*" name="email" type="text" outlined dense :disabled="sending"
              v-validate.disable="'required'" :error-messages="errors.collect('Email')" data-vv-name="Email" required
              autocomplete="off" v-model="registration.email"></v-text-field>
          </v-col>

          <v-col class="py-1" cols="12" md="6">
            <v-text-field id="password" label="Password*" name="password" ref="password" outlined dense
              :type="showPassword ? 'text' : 'password'" autocomplete="off" :disabled="sending"
              :error-messages="errors.collect('Password')" v-validate="'required|min:8|verify_password'"
              data-vv-name="Password" required v-model="registration.password" append-icon="mdi-eye"
              @click:append="showPassword = !showPassword"></v-text-field>
          </v-col>
          <v-col class="py-1" cols="12" md="6">
            <v-text-field label="Confirm Password*" :type="showPassword ? 'text' : 'password'" outlined dense
              :disabled="sending" v-validate="'required|confirmed:password'" data-vv-validate-on="change"
              :error-messages="errors.collect('Confirm Password')" data-vv-name="Confirm Password" data-vv-as="Password"
              required v-model="registration.confirmPassword" append-icon="mdi-eye"
              @click:append="showPassword = !showPassword"></v-text-field>
          </v-col>
          <v-col class="py-1" cols="12">
            <v-combobox ref="regComboBox" v-model="registration.organization" :items="foundOrgs" :loading="loading"
              :search-input.sync="searchOrgs" cache-items eager outlined dense
              hint="If your organization doesn't appear, please type it in fully to add it." :disabled="sending"
              data-vv-name="Organization" item-text="name" item-value="id " required v-validate="'required'"
              :error-messages="errors.collect('Organization')" label="Organization Name*"
              placeholder="Start typing to Search" autocomplete="off">
            </v-combobox>
            <!-- <v-autocomplete
              v-if="!newOrg"
              v-model="registration.organization"
              :items="foundOrgs"
              :loading="loading"
              :search-input.sync="searchOrgs"
              cache-items
              eager
              outlined
              dense
              :disabled="sending"
              item-text="name"
              item-value="id"
              label="Organization Name"
              placeholder="Start typing to Search"
              autocomplete="off"
            > -->
            <!-- <template v-slot:no-data>
                <v-list-item v-if="!loading">
                  <v-list-item-title>
                    Organization not found.
                    <a @click="addOrg">Click Here</a>
                    to add it
                  </v-list-item-title>
                </v-list-item>
              </template> -->
            <!-- </v-autocomplete>
            <v-text-field
              v-if="newOrg"
              label="Organization"
              name="organization"
              type="text"
              outlined
              readonly
              dense
              :disabled="sending"
              autocomplete="off"
              v-validate.disable="'required'"
              :error-messages="errors.collect('Organization')"
              data-vv-name="Organization"
              required
              v-model="registration.organization.name"
            ></v-text-field> -->

            <v-btn :disabled="sending" type="submit" :loading="sending" class="main-btn">Sign Up</v-btn>
            <br class="d-md-none my-2" />
            <br />
          </v-col>
        </v-row>
      </v-form>
    </div>
  </v-card>
</template>

<style scoped lang="scss">
.no-left-pad-md {
  padding: 16px 0;
}
</style>

<script>
import Vue from "vue";
import logo from "../../assets/images/logo.png";

export default {
  name: "Registration",
  components: {
  },
  data() {
    return {
      registration: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        organization: null,
      },
      loading: false,
      newOrg: false,
      foundOrgs: [],
      searchOrgs: null,
      sending: false,
      logo: logo,
      organizationAddDialogVisible: false,
      showPassword: false,
    };
  },
  watch: {
    searchOrgs(val) {
      val = val.trim();
      if (!val || val.length < 3) {
        this.foundOrgs = [];
        return;
      }
      this.loading = true;
      Vue.$organizationService.autocomplete(val).then((orgs) => {
        this.foundOrgs = orgs;
        this.loading = false;
      });
    },
  },
  methods: {
    // addOrg() {
    //   // this.organizationAddDialogVisible = true;
    //   this.newOrg = true;

    // },
    // orgSaved(organization) {
    //   this.registration.organization = organization;
    //    this.organizationAddDialogVisible = false;
    // },
    async register() {
      this.$refs["regComboBox"].blur();
      await this.$nextTick();
      this.addDialog = false;
      let result;
      try {
        result = await this.$validator.validateAll();
        if (!result) {
          return result;
        }
      } catch (err) {
        return;
      }

      if (!result) {
        Vue.$eventBus.$emit("notifyUser", "Fix the invalid fields");
        return false;
      }
      this.sending = true;

      try {
        this.registration.username = this.registration.email;
        this.registration.organization =
          typeof this.registration.organization === "object"
            ? this.registration.organization
            : { name: this.registration.organization };
        await Vue.$userService.register(this.registration);
        this.sending = false;
        this.$router.push("completion");
      } catch (err) {
        this.sending = false;
        console.log(err.response.data.message[0].messages[0].message);
        var message = err.response.data.message[0].messages[0].message;
        Vue.$eventBus.$emit("notifyUser", message);
      }
    },
  },
};
</script>