<template>
  <v-card elevation="12" style="background: white !important; width: 100%" class="pa-4">
    <v-card-text>
      <h2 class="black--text mb-2">Sign In</h2>
      <v-form v-on:submit.prevent="loginSubmission">
        <v-row>
          <v-col cols="12">
            <v-text-field label="Login" name="login" type="text" outlined dense :disabled="sending"
              v-validate.disable="'required'" :error-messages="errors.collect('Email')" data-vv-name="Email" required
              v-model="email"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field id="password" label="Password" name="password" outlined dense
              :type="showPassword ? 'text' : 'password'" :disabled="sending"
              :error-messages="errors.collect('Password')" v-validate="'required'" data-vv-name="Password" required
              v-model="password" append-icon="mdi-eye" @click:append="showPassword = !showPassword"></v-text-field>
          </v-col>
        </v-row>
        <v-row class="login-action-row">
          <v-col cols="6" class="text-left">
            <v-btn :disabled="sending" type="submit" :loading="sending" class="main-btn">
              Login
            </v-btn>
          </v-col>
          <v-col cols="6" class="text-right">
            <router-link class="forgot-link" to="/auth/forgotPassword">Forgot Password?</router-link>
          </v-col>
        </v-row>
      </v-form>
      <hr class="mt-4 mb-4" />
      <v-row>
        <v-col cols="12">
          <h2 class="black--text">Sign Up</h2>
        </v-col>
        <v-col cols="12">
          <span class="black--text">
            Impact HQ supports nonprofits serving 9 counties throughout WNY.
            Check to see if your organization is eligible for Impact-HQ.
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-btn to="/auth/registration" class="main-btn">Create an Account</v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<style scoped lang="scss">
@import "~vuetify/src/styles/settings/_variables";

h3.tagline {
  font-size: 35px;
  font-weight: 500;
  color: #171b5b;
  margin: 35px 0;
}

.form-pinch {
  padding: 10% 10% 0 10%;
}

.row.login-action-row {
  margin-top: -30px;
}

.forgot-link {
  color: black;
  text-decoration: none;
  display: block;
  position: relative;
  top: -18px;
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .no-left-pad-md {
    padding-left: 0;
  }
}
</style>

<script>
import Vue from "vue";
import logo from "../../assets/images/logo.png";

export default {
  name: "Login",
  beforeRouteEnter(to, from, next) {
    if (to.query.emailConfirmed) {
      Vue.$eventBus.$emit("notifyUser", "Email Verified! Please Log In");
    }
    next();
  },
  data() {
    return {
      email: null,
      password: null,
      sending: false,
      logo: logo,
      showPassword: false,
    };
  },
  methods: {
    async loginSubmission() {
      let result;
      try {
        result = await this.$validator.validateAll();
        if (!result) {
          return result;
        }
      } catch (err) {
        return;
      }

      if (!result) {
        Vue.$eventBus.$emit("notifyUser", "Fix the invalid fields");
        return false;
      }

      this.sending = true;
      try {
        await Vue.$userService.login(this.email, this.password);
        this.$router.push("/dashboard");
      } catch (err) {
        this.sending = false;
      }
    },
  },
};
</script>