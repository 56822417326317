<template>
  <v-card rounded="lg" class="px-11 pt-14 pb-9" elevation="1">
    <v-row class="hero-box">
      <v-col cols="12" class="flex-item-left">
        <h2 class="themepurple">MY ACCOUNT</h2>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-tabs color="#020659">
                <v-tab>General Info</v-tab>
                <v-tab>Change Password</v-tab>
                <!-- <v-tab>My Organization</v-tab> -->
                <v-tab-item>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field label="First Name" v-model="generalInfo.firstName" data-vv-name="First Name"
                          data-vv-scope="general" :error-messages="errors.collect('First Name', 'general')"
                          v-validate="'required'"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field label="Last Name" v-model="generalInfo.lastName" data-vv-name="Last Name"
                          data-vv-scope="general" :error-messages="errors.collect('Last Name', 'general')"
                          v-validate="'required'"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field label="E-Mail" v-model="generalInfo.email" data-vv-name="Email"
                          data-vv-scope="general" :error-messages="errors.collect('Email', 'general')"
                          v-validate="'required|email'"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field label="Organization Name" readonly
                          v-model="generalInfo.organizationName"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="text-right">
                        <v-btn @click="saveGeneralInfo" color="#F28705" class="white--text"
                          :loading="sending">Save</v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-tab-item>
                <v-tab-item>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field id="password" label="Password*" name="password" ref="password"
                          :type="showPassword ? 'text' : 'password'" autocomplete="off" :disabled="sending"
                          :error-messages="errors.collect('Password', 'security')"
                          v-validate="'required|min:8|verify_password'" data-vv-name="Password" data-vv-scope="security"
                          required v-model="generalInfo.password" append-icon="mdi-eye"
                          @click:append="showPassword = !showPassword"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field label="Confirm Password*" :type="showPassword ? 'text' : 'password'"
                          :disabled="sending" v-validate="'required|confirmed:password'" data-vv-validate-on="change"
                          :error-messages="errors.collect('Confirm Password', 'security')" data-vv-scope="security"
                          data-vv-name="Confirm Password" data-vv-as="Password" required
                          v-model="generalInfo.confirmPassword" append-icon="mdi-eye"
                          @click:append="showPassword = !showPassword"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="8">
                        To change your password, please enter, and confirm, your new password
                      </v-col>
                      <v-col cols="4" class="text-right">
                        <v-btn @click="savePassword" color="#F28705" class="white--text" :loading="sending">Save
                          Password</v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-tab-item>
              </v-tabs>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" v-if="OrgHasGrants && CanViewGrants && GrantsApproved">
              <v-btn :to="{
                name: 'planning-grant-post-approval',
              }" depressed color="#F28705" class="white--text my-5">VIEW MY GRANT</v-btn>
            </v-col>
            <v-col cols="12" v-if="generalInfo.IsCapacityBuildingSpecialist">
              <v-btn @click="organizationProfileEditDialogVisible = true" color="#F28705" class="white--text">Edit
                Capacity Building Specialist Profile</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-dialog v-model="organizationProfileEditDialogVisible" max-width="1700">
      <OrganizationProfileEdit v-if="organizationProfileEditDialogVisible"
        @closed="organizationProfileEditDialogVisible = false"></OrganizationProfileEdit>
    </v-dialog>
  </v-card>
</template>

<script>
import Vue from "vue";
import OrganizationProfileEdit from "../components/popups/OrganizationProfileEdit.vue";

export default {
  async beforeRouteEnter(to, from, next) {
    var currentUser = await Vue.$userService.current();
    var grants = await Vue.$grantFormService.find({
      pagination: {
        page: 1,
        pageSize: 10
      },
      filters: {
        OrganizationId: currentUser.organization.id
      }
    });
    next((vm) => {
      vm.OrgHasGrants = grants.data.data.length > 0;
      vm.CanViewGrants = currentUser.CanViewGrants;
      vm.GrantsApproved = grants.data.data.filter((grant) => grant.attributes.isApproved).length > 0;
      vm.generalInfo = {
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        email: currentUser.email,
        organizationName: currentUser.organization.name,
        IsCapacityBuildingSpecialist: currentUser.IsCapacityBuildingSpecialist
      };
    });
  },
  name: "account",
  components: {
    OrganizationProfileEdit,
  },
  data() {
    return {
      showPassword: false,
      CanViewGrants: false,
      OrgHasGrants: false,
      GrantsApproved: false,
      organizationProfileEditDialogVisible: false,
      confirmChangePasswordDialog: false,
      generalInfo: {
        firstName: null,
        lastName: null,
        email: null,
        organizationName: null,
        IsCapacityBuildingSpecialist: false
      }
    };
  },
  methods: {
    async saveGeneralInfo() {
      try {
        var result = await this.$validator.validate('general.*');
        if (!result) {
          Vue.$eventBus.$emit("notifyUser", "Please check form for invalid fields");
          return result;
        }
      } catch (err) {
        Vue.$eventBus.$emit("notifyUser", "Please check form for invalid fields");
        return;
      }

      this.sending = true;

      try {
        await Vue.$userService.save({
          firstName: this.generalInfo.firstName,
          lastName: this.generalInfo.lastName,
          email: this.generalInfo.email,
        });
        Vue.$eventBus.$emit("notifyUser", "General Info Saved");
      } finally {
        this.sending = false;
      }
    },

    async savePassword() {
      try {
        var result = await this.$validator.validate("security.*");
        if (!result) {
          return result;
        }
      } catch (err) {
        return;
      }

      this.sending = true;

      try {
        await Vue.$userService.save({
          password: this.generalInfo.password,
        });
        this.generalInfo.password = "";
        this.generalInfo.confirmPassword = "";
        Vue.$eventBus.$emit("notifyUser", "Password Saved");
      } finally {
        this.sending = false;
      }
    },
  },
};
</script>