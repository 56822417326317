<template>
  <div>
    <v-card class="px-11 py-11" rounded="lg" elevation="1">
      <h3 style="color: #00bf43">
        {{ moment(Training.attributes.Date).format("LL") }}
      </h3>
      <h1 class="themepurple mb-2">
        {{ Training.attributes.Title }}
      </h1>
      <hr class="my-5" />
      <v-row>
        <v-col cols="12" lg="4" md="6">
          <div>
            <b class="mr-4 themepurple">OFFERED BY:</b> {{ Training.attributes.OfferedBy }}
          </div>
          <div class="my-2">
            <b class="mr-4 themepurple">DATE:</b>{{moment(Training.attributes.Date).format("MMMM Do YYYY")}}
          </div>
          <div class="my-2">
            <b class="mr-4 themepurple">TIME:</b>{{Training.attributes.Time}}
          </div>
        </v-col>
        <v-col cols="12" lg="4" md="6">
          <div>
            <b class="mr-4 themepurple">LOCATION: </b> {{ Training.attributes.Location }}
          </div>
          <!-- {{ Training }} -->
          <div class="my-2" v-if="Training.attributes.Attachment.data">
            <b class="mr-4 themepurple">ATTACHMENT:</b>
            <a
              target="_blank"
              :href="
                process.env.VUE_APP_API_BASE_URL +
                Training.attributes.Attachment.data.attributes.url
              "
              >{{ Training.attributes.Attachment.data.attributes.name }}</a
            >
          </div>
        </v-col>
        <v-col cols="12" lg="4" md="6">
          <div><b class="mr-4 themepurple">COST:</b> {{ Training.attributes.Cost }}</div>
        </v-col>
      </v-row>
      <div v-if="parsedWebsite">
        <v-btn
          depressed
          color="#F28705"
          class="white--text my-5"
          :href="parsedWebsite"
          target="_blank"
        >
          Website/Registration
        </v-btn>
      </div>
      <div class="mt-7 mb-3 themepurple"><b> PROGRAM OVERVIEW: </b></div>

      <span v-html="Training.attributes.Overview"></span>

      <div class="d-flex mt-12">
        <v-card flat to="/training-event" class="themepurple link"
          >> BACK TO ALL TRAINING EVENTS</v-card
        >
      </div>
    </v-card>
  </div>
</template>

<script>
import Vue from "vue";
var moment = require("moment");

export default {
  name: "Training",

  filters: {},
  data() {
    return {
      Training: {
        attributes: {},
      },
      Trainings: [],
      moment: moment,
    };
  },
  computed: {
    parsedWebsite() {
      if (!this.Training.attributes.Website) {
        return null;
      }
      if (!this.Training.attributes.Website.startsWith("http")) {
        return "https://" + this.Training.attributes.Website;
      }
      return this.Training.attributes.Website;
    },
  },
  async beforeRouteEnter(to, from, next) {
    try {
      var Training = await Vue.$trainingService.findOneBySlug(to.params.slug);

      return next((vm) => {
        vm.Training = Training;
      });
    } catch (err) {
      console.log(err);
      next(false);
    }
  },
  async beforeRouteUpdate(to, from, next) {
    try {
      this.Training = await Vue.$trainingService.findOnebySlug(to.params.slug);

      return next();
    } catch (err) {
      console.log(err);
      next(false);
    }
  },
};
</script>

<style scoped>
h3 {
  font-weight:500;
  text-transform: uppercase;
}

h5 {
  font-weight: normal;
}

</style>
