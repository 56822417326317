// content resource service
export default {
    install: (Vue) => {
      //private
    const route = process.env.VUE_APP_API_BASE_URL + '/api/resource-forms/';
  
      //public
      Vue.$resourceFormService = {
        create(params) {
          return Vue.axios.post(route, {
            data: params
          });
        }
      };
    }
  };