// industry news service

export default {
    install: (Vue) => {
        //private
        const route = process.env.VUE_APP_API_BASE_URL + '/api/new-on-impacts';
    
        //public
        Vue.$newImpactService = {
            find(params) {
                return Vue.axios.get(route, {
                    params: params
                });
            },

            findOne(key, params) {
                return Vue.axios.get(`${route}/${key}`, {
                  params: params
                });
              },

              async findOneBySlug(slug, params) {
                var results = await Vue.axios.get(`${route}?filters[slug]=${slug}`, {
                  params: params
                });
                console.log(results);
                return results.data.data[0]
            
            },

            findLatest(params) {
              return Vue.axios.get(`${route}?pagination[limit]=3&sort[0]=createdAt:desc`, {
                params: params
            });
            }
      
        };
    
    }

};