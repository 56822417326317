// content resource service
export default {
    install: (Vue) => {
      //private
      const route = process.env.VUE_APP_API_BASE_URL + '/api/resources/';
  
      //public
      Vue.$resourceService = {
        async find(params) {
          var ret =  await Vue.axios.get(route, {
            params: params
          });

          return {
            data: {
              data: ret.data.data.map((item) => ({
                ...item,
                attributes: {
                  ...item.attributes,
                  resourceType: item.attributes.resourceType === 'Other' ? item.attributes.otherResourceType : item.attributes.resourceType
                }
              }))
            }
          }
        },
  
        async findOne(key, params) {
          var ret = await Vue.axios.get(`${route}${key}`, {
            params: params
          });

          return {
            data: {
              data: {
                ...ret.data.data,
                attributes: {
                  ...ret.data.data.attributes,
                  resourceType: ret.data.data.attributes.resourceType === 'Other' ? ret.data.data.attributes.resourceTypeOther : ret.data.data.attributes.resourceType
                }
              }
            }
          }
        }
      };
    }
  };